var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-page chart-page"},[_c('div',{staticClass:"container"},[_c('Calendar',{staticClass:"chart-calendar",attrs:{"attributesCalendar":_vm.attributesCalendar,"сategories":_vm.selectTabId},on:{"updateDate":_vm.updateDate}}),(_vm.selectTabId == 2)?_c('AllInOneChart',{attrs:{"prop-data":{
        data: _vm.monthChartData,
        dates: _vm.monthChartData.date,
      }}}):_c('div',_vm._l((_vm.filteredSelectedCharts),function(chart){return _c('div',{key:chart.id,staticClass:"charts-holder"},[(chart.isShow === true)?_c('div',[_c(chart.componentName,{tag:"component",attrs:{"height":chart.height,"prop-chart-data":{
              values: _vm.monthChartData[chart.id],
              dates: _vm.monthChartData.date,
              title: chart.title,
            }}}),(_vm.monthChartData.date.length <= 0)?_c('span',{staticClass:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("common.no_data"))+" ")]):_vm._e()],1):_vm._e()])}),0),_c('div',{staticClass:"footer-btns"},[_c('van-button',{staticClass:"button-white",attrs:{"type":"primary"},on:{"click":function($event){_vm.isShowChartsModal = true}}},[_vm._v(" "+_vm._s(_vm.$t("chart.btn_1"))+" ")]),_c('van-button',{staticClass:"button-white",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goToRoute('Calendar')}}},[_vm._v(" "+_vm._s(_vm.$t("common.view_calendar"))+" ")]),_c('van-button',{staticClass:"button-black",attrs:{"type":"primary"}},[_vm._v(_vm._s(_vm.$t("calendar.button2")))])],1),_c('van-dialog',{staticClass:"mood-modal",attrs:{"show-confirm-button":false,"overlay":true,"closeable":"","close-icon":"cross","close-icon-position":"top-left","close-on-click-overlay":true},on:{"click-overlay":function($event){return _vm.updateDate(_vm.selectedDate)}},model:{value:(_vm.isShowChartsModal),callback:function ($$v) {_vm.isShowChartsModal=$$v},expression:"isShowChartsModal"}},[_c('ScrollBar',{attrs:{"scroll-settings":{
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false,
        },"height":"310"}},[_c('ul',{staticClass:"checkboxs-list"},_vm._l((_vm.filteredSelectedCharts),function(item,index){return _c('li',{key:index,staticClass:"list-item"},[_c('van-checkbox',{class:item.isShow === true ? 'checked' : null,attrs:{"checked-color":"#000000","icon-size":"16px"},model:{value:(item.isShow),callback:function ($$v) {_vm.$set(item, "isShow", $$v)},expression:"item.isShow"}},[_vm._v(_vm._s(item.title))])],1)}),0)]),_c('h3',{staticClass:"tabs-title"},[_vm._v("выводить графики:")]),_c('Tabs',{attrs:{"array":_vm.tabsArray,"select-id-in":_vm.selectTabId},model:{value:(_vm.selectTabId),callback:function ($$v) {_vm.selectTabId=$$v},expression:"selectTabId"}}),_c('van-button',{staticClass:"button-black",attrs:{"type":"primary"},on:{"click":function($event){(_vm.isShowChartsModal = false), _vm.updateDate(_vm.selectedDate)}}},[_vm._v(_vm._s(_vm.$t("buttons.complete")))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }